import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99')
];

export const server_loads = [2,3,4,8,9];

export const dictionary = {
		"/(base)/(web)": [13,[2,3]],
		"/(base)/(web)/(authenticated)/(admin)/admin": [14,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/accounts": [15,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/accounts/[id]": [~16,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/connect/providers": [17,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/connect/providers/[id]": [18,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/portfolios/[id]": [19,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/portfolios/[id]/bulk-update": [20,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties": [21,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]": [22,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/clone": [23,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/debug": [24,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/update": [~25,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/purgeRedis": [~26,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/queue": [27,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/sync": [28,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/temp": [29,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools": [30,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools/pdf-reader": [31,[2,4]],
		"/(base)/(web)/(authenticated)/billing": [33,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout": [~34,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout/bypass": [~35,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout/first-free": [~36,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout/success": [~37,[2,3]],
		"/(base)/(web)/(authenticated)/connect/providers": [38,[2,3]],
		"/(base)/(web)/(authenticated)/connect/providers/[id]": [39,[2,3]],
		"/(base)/(web)/contact-us": [67,[2,3]],
		"/(base)/(web)/glossary": [68,[2,3]],
		"/(base)/(web)/login": [69,[2,3]],
		"/(base)/(web)/logout": [70,[2,3]],
		"/(base)/(web)/map": [71,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios": [40,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/add-properties": [47,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/add": [46,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]": [41,[2,5]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/all": [42,[2,7]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/dashboard": [43,[2,6]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/properties": [44,[2,6]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/well-interest": [45,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties": [48,[2,3]],
		"/(base)/(web)/(authenticated)/my-properties/add": [~64,[2,3]],
		"/(base)/(web)/(authenticated)/my-properties/quick-add": [65,[2,3]],
		"/(base)/(web)/(authenticated)/my-properties/[id]": [~49,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/build-estimate": [50,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details": [51,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/beyond-producing-wells": [52,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/geographic-info": [53,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/lease-estimate": [54,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/monthly-revenue": [55,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/new-drilling": [56,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/new-permits": [57,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/new-wells": [58,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/property-info": [~59,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/sales-estimate": [60,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/well-information": [61,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/print-manager": [62,[2,8]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/well-interest": [~63,[2,8]],
		"/(base)/(web)/privacy-policy": [96,[2,3]],
		"/(base)/(web)/(authenticated)/profile": [~66,[2,3]],
		"/(base)/(web)/p/[id]": [~72,[2,9]],
		"/(base)/(web)/p/[id]/component/map": [73,[2,10]],
		"/(base)/(web)/p/[id]/details/beyond-producing-wells": [74,[2,9]],
		"/(base)/(web)/p/[id]/details/geographic-info": [75,[2,9]],
		"/(base)/(web)/p/[id]/details/lease-estimate": [76,[2,9]],
		"/(base)/(web)/p/[id]/details/monthly-revenue": [77,[2,9]],
		"/(base)/(web)/p/[id]/details/new-drilling": [78,[2,9]],
		"/(base)/(web)/p/[id]/details/new-permits": [79,[2,9]],
		"/(base)/(web)/p/[id]/details/new-wells": [80,[2,9]],
		"/(base)/(web)/p/[id]/details/property-info": [81,[2,9]],
		"/(base)/(web)/p/[id]/details/sales-estimate": [82,[2,9]],
		"/(base)/(web)/p/[id]/details/well-information": [83,[2,9]],
		"/(base)/(web)/p/[id]/print-manager": [95,[2,9]],
		"/(base)/(web)/p/[id]/print/all": [84,[2,12]],
		"/(base)/(web)/p/[id]/print/dashboard": [~85,[2,11]],
		"/(base)/(web)/p/[id]/print/details/beyond-producing-wells": [86,[2,11]],
		"/(base)/(web)/p/[id]/print/details/lease-estimate": [87,[2,11]],
		"/(base)/(web)/p/[id]/print/details/monthly-revenue": [88,[2,11]],
		"/(base)/(web)/p/[id]/print/details/new-drilling": [89,[2,11]],
		"/(base)/(web)/p/[id]/print/details/new-permits": [90,[2,11]],
		"/(base)/(web)/p/[id]/print/details/new-wells": [91,[2,11]],
		"/(base)/(web)/p/[id]/print/details/sales-estimate": [92,[2,11]],
		"/(base)/(web)/p/[id]/print/details/well-information": [93,[2,11]],
		"/(base)/(web)/p/[id]/print/well-interest": [~94,[2,11]],
		"/(base)/(web)/reset-password": [97,[2,3]],
		"/(base)/(web)/signup": [98,[2,3]],
		"/(base)/(web)/(authenticated)/(enterprise)/speculator": [32,[2,3]],
		"/(base)/(web)/terms-conditions": [99,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';